
import { Component, Vue } from "vue-property-decorator";

@Component
export default class BottomNavigationComponent extends Vue {
  get appData() {
    return this.$store.getters["app/data"];
  }

  get newsData() {
    return this.$store.getters["news/data"];
  }

  get unreadNews() {
    return this.newsData?.filter(news => news.reading_date == null);
  }

  get notrufTile() {
    return this.appData.tiles.find(tile => tile.type.name == "Notruf");
  }

  navigationIncludes(item: string): boolean {
    return this.appData?.main_scr_bottom_navigation?.includes(item);
  }
}
